td.header {
  height: 140px;
}

td.AutoWidth, th.AutoWidth {
  width: 1px;
  white-space: nowrap;
}

table.AutoWidth th, table.AutoWidth td {
  max-width: 1px;
}

.GeneralTable {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  border: solid 1px #000;
}

.GeneralTable .selected {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png")
}

.GeneralTable th {
  font-size: 1.25rem;
	font-family: MedievalSharp;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	border: solid 1px #000;
	color: #e9c776;
	text-align: center;
  font-weight: normal;
  padding: 0.3rem 0.3rem;
}

.GeneralTable td {
  padding: 0.3rem 0.3rem;
  text-align: left;
}

.GeneralTable tr:nth-child(odd) td {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png")
}

.GeneralTable tr:hover td {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png")
}
