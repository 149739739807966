.Alphabox {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 1px;
  border-style: solid;
}

.GeneralBox {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  padding: 16px;
  border: 1px solid black;
  /*margin: auto;*/
  /*text-align: center;*/
}

.DarkBackground {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}
.NeutralBackground {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
}

.GeneralBox.DarkBox {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  border: 1px solid #e9c776;
}

.GeneralBox.ChooseBox {
  opacity: 0.7;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  border: 1px solid #e9c776;
  cursor: pointer;
}

.GeneralBox.ChooseBox:hover {
  opacity: 1;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
}

.GeneralBox.ChooseBox:active, .GeneralBox.ChooseBox.active {
  opacity: 1;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
  border: 3px solid #e9c776;
}

.GeneralBox .Title {
  font-family: MedievalSharp;
  color: #e9c776;
  font-size: 1.5rem;
}

.GeneralBox .SubTitle {
  color: #e9c776;
  font-size: 1.1rem;
}

/*.GeneralBlock {
  border: #000 solid 1px;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
}

.GeneralBlock .Title {
  font-family: MedievalSharp;
  color: #e9c776;
  display: inline-block;
  font-size: 2rem;
  padding: 15px;
  margin: 0px -15px 0px 0px;
}*/

.GeneralBox .Coloured {
  color: #e9c776;
  font-size: 1.05rem;
}

.GeneralBox.GoldBorder {
  border: 2px groove #e9c776;
}

/*.GeneralBlock .Filters {
  float: right;
  display: inline-block;
  padding: 15px 15px 0px 0px;
  cursor: pointer;
}*/

/*.GeneralBlock .Rotate {
  transition: all 4s ease;
}

.GeneralBlock .Rotate:hover {
  transform: rotate(360deg);
}*/

/*.CityCenterPlayers .LineBreak {
  height: 3px;
  border: 0px;
  padding: 0px;
  margin: 0px;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}*/

/*.CityCenterPlayers .LeftAligned {
  float: left;
  display: inline-block;
  padding: 2px;
}*/

/*.CityCenterPlayers .RightAligned {
  float: right;
  display: inline-block;
  padding: 2px;
}*/

/*.CityCenterPlayers .Padded {
  padding: 10px;
  margin-left: 50px;
  margin-right: 50px;
}*/

.CityCenterPlayers .Locate {
  cursor: pointer;
  color: orange;
  font-family: Trajan;
  font-size: 0.75rem;
  border: 1px solid orange;
  display: inline-block;
  padding: 2px;
}

.CityCenterPlayers .Locate:hover {
  cursor: pointer;
  font-family: Trajan;
  font-size: 0.75rem;
  border: 1px solid orange;
  display: inline-block;
  padding: 2px;
}

.CityCenterPlayers .LocateArrow {
  opacity: 0.3;
  height: 16px;
  width: 16px;
  transition: all 800ms ease;
  vertical-align: text-bottom;
}

.CityCenterPlayers .Locate:hover .LocateArrow {
  opacity: 1;
  transform: translate(30px,0px);
}

.staticmodal {
  display: block;
  position: fixed;
  z-index: 1150;
  left: 0;
  top: 0;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #0006;
  -webkit-transform: translate3d(0,0,0); // Myth: Fix for Safari on mobile devices, hiding the content underneath the header
}

.staticmodal .modal-content {
    width: 80%;
  height: auto;
}

/*.hidden-modal {
  display: none;
}*/

/*.modal.fullscreen {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
  font-size: 0.9rem;
  text-align: left;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  overflow-y: auto;*/ /* Enable scroll if needed */
  /*overflow-x: hidden;
  border: 1px solid #888;
  padding: 20px;
  height: auto;
  width: auto;
}*/

/* Modal Content */
.modal-content {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
}

.SmallModal {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  text-align: center;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: 40%;
  height: auto;
}

.floating-box {
  @extend .GeneralBox, .DarkBox;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TransparantBG
{
    background-color: transparent;
}
