
.button,
.submit {
  background-color: #CBAD64;
  font-size: 0.75rem;
  padding: 5px 6px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: default;
  color: #222;
  text-align: center;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
  /*font-family: Arial, Helvetica, sans-serif*/
}

.button.toggled {
  background-color: #705225;
  color: white;
}

.DarkButton {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 8px;
  font-size: 1.25rem;
  font-family: Vinque;
  color: #e9c776;
  text-align: center;
  cursor: pointer;
}

.DarkButton:hover {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  border: 1px solid #e9c776;
}

.GeneralButton {
  font-size: 1rem;
  text-align: center;
  padding: 5px;
  font-family: MedievalSharp;
  cursor: pointer;
  border: 1px solid #000;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  color: #fff;
}

.GeneralButton:disabled {
  color: #404040;
  cursor: initial;
}

.GeneralButton:hover:not(:disabled) {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
  border: 1px solid #e9c776;
  color: #e9c776;
}

.GeneralButton:active {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}

.GeneralButton.LargeButton {
  font-size: 1.25rem;
  padding: 6px 7px;    
}

.GeneralButton.SmallButton {
  font-size: 0.8rem;
  padding: 3px 4px;    
}

.GeneralButton.LightButton {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
}

.RedButton {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Normal.png"), url("/assets/images/ui/Resizable_Button1_R-End_Normal.png"), url("/assets/images/ui/Resizable_Button1_Tile_Normal.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	padding: 0.3rem 0.6rem;
	font-size: 0.8rem;
	font-family: Trajan;
	color:#e9c776;
	cursor:pointer;
	border: 0px;
}

.RedButton.LargeButton {
  font-size: 1.25rem;
  padding: 0.6rem 1rem;
}

.RedButton:hover:not(:disabled) {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Hover.png"), url("/assets/images/ui/Resizable_Button1_R-End_Hover.png"), url("/assets/images/ui/Resizable_Button1_Tile_Hover.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	color:#fff;
}

.RedButton:active:not(:disabled) {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_R-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_Tile_Clicked.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	color:#fff;
}
.RedButton:disabled {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_R-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_Tile_Clicked.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	color:#fff;
	-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

.ButtonGroup {
  display: inline-flex;
  flex-flow: row wrap;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  padding: 0.3rem;
  border-radius: 0.6rem;
}

.ButtonGroup .GeneralButton:hover {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
  color: #e9c776;
  border: 1px solid #000;
}

.ButtonGroup .GeneralButton.Active, .ButtonGroup .GeneralButton:active {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  color: #e9c776;
  border: 1px solid #000;
}

.floating-up-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.floating-up-button button {
  font-size: 3rem;
}
