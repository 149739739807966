h1 {
  font-size: 2rem;
  line-height: 30px;
  font-weight: normal;
  color: #FFFFFF;
  margin-top: 0;
}

h2 {
  font-size: 1.25rem;
  text-transform: capitalize;
  color: #d08506;
  font-weight: normal;
}

h3 {
  font-size: 1rem;
  font-weight: normal;
}
