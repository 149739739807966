/*// Required
@import "../node_modules/bootstrap/scss/functions";*/

/*Override Bootstrap defaults*/
/*@import './styles/bootstrap-overrides.scss';

// Required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// Optional Bootstrap components here
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";*/

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Import our files */
@import './styles/animations.scss';
@import './styles/blocks.scss';
@import './styles/buttons.scss';
@import './styles/fonts.scss';
@import './styles/headers.scss';
@import './styles/inputs.scss';
@import './styles/tables.scss';
@import './styles/text.scss';

.golden-arrow {
    transition: 0.3s ease-out;
}

.game-icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    cursor: pointer;
}

.game-icon.small {
    width: 1rem;
    height: 1rem;
}

.golden-arrow.inverted-golden-arrow {
    transform: rotate(180deg);
}


.even {
    background-color: #1d2c37;
}

#home_contentimg {
    width: 500px;
    margin: 10px auto;
    text-align: center;
}

#home_contentimg img {
    height: 145px;
    width: 145px;
    border: 2px solid #c37709;
    margin: 0 8px;
    display: inline-block;
}

table.login {
    color: black;
}



.Padding10 {
    padding-left: 10px;
    padding-right: 10px;
}

.FlexWrap{
    display: flex;
    flex-wrap: wrap;
}

input:disabled,
input:disabled:hover {
    color: #444;
    background-color: #aaa;
    cursor: default;
}

.shopbutton {
    background-color: #CBAD64;
    font-size: 12px;
    height: 26px;
    line-height: 26px;
    padding: 2px 6px 2px 38px;
    font-weight: bold;
    white-space: nowrap;
    color: #222;
    text-align: left;
    margin: 2px 0px;
    display: inline-block;
    overflow-x: hidden;
    width: 99px;
    background-repeat: no-repeat;
    box-sizing: content-box;
}

.new-shopbutton {
  background-color: #CBAD64;
  padding: 5px 0px 6px 35px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #222;
  text-align: left;
  margin: 5px 0px;
  display: block;
  overflow-x: hidden;
  background-repeat: no-repeat;
  font-weight: bold;
  font-family: MedievalSharp;
  font-size: 0.8rem;
}


.shopbutton:hover, .new-shopbutton:hover {
    background-color: rgb(112, 82, 37);
    color: white;
    text-decoration: none;
}

.shopbutton.hospital, .new-shopbutton.hospital {
    background-image: url('/assets/images/icons/hospital.png');
}

.shopbutton.barracks, .new-shopbutton.barracks {
    background-image: url('/assets/images/icons/barracks.png');
}

.shopbutton.castle, .new-shopbutton.castle {
    background-image: url('/assets/images/icons/castle.png');
}

.shopbutton.armory, .new-shopbutton.armory {
    background-image: url('/assets/images/icons/armory.png');
}

.shopbutton.tournament, .new-shopbutton.tournament {
    background-image: url('/assets/images/icons/tournament.png');
}

.shopbutton.port, .new-shopbutton.port {
  background-image: url('/assets/images/icons/port.png');
}

.shopbutton.shipyard, .new-shopbutton.shipyard {
  background-image: url('/assets/images/icons/shipyard.png');
}

.shopbutton.headquarters, .new-shopbutton.headquarters {
    background-image: url('/assets/images/icons/headquarters.png');
}

.shopbutton.train,
input.train, .new-shopbutton.train {
    background-image: url('/assets/images/icons/train.png');
}

    .shopbutton.forest,
    .terrain-button.forest,
    input.forest, .new-shopbutton.forest {
        background-image: url('/assets/images/icons/train-forest.png');
    }

    .shopbutton.plains,
    .terrain-button.plains,
    input.plains, .new-shopbutton.plains {
        background-image: url('/assets/images/icons/train-plains.png');
    }

    .shopbutton.mountains,
    .terrain-button.mountains,
    input.mountains, .new-shopbutton.mountains {
        background-image: url('/assets/images/icons/train-mountains.png');
    }

    .shopbutton.desert,
    .terrain-button.desert,
    input.desert, .new-shopbutton.desert {
        background-image: url('/assets/images/icons/train-desert.png');
    }

    .shopbutton.jungle,
    .terrain-button.jungle,
    input.jungle, .new-shopbutton.jungle {
        background-image: url('/assets/images/icons/train-jungle.png');
    }

    .shopbutton.snow,
    .terrain-button.snow,
    input.snow, .new-shopbutton.snow {
        background-image: url('/assets/images/icons/train-snow.png');
    }

    .shopbutton.wilderness,
    .terrain-button.wilderness,
    input.wilderness, .new-shopbutton.wilderness {
        background-image: url('/assets/images/icons/train-wilderness.png');
    }

    .shopbutton.wetlands,
    .terrain-button.wetlands,
    input.wetlands {
      /*background-color: rgb(153, 153, 255);*/
      background-image: url('/assets/images/icons/train-wetlands.png?v=2');
    }

.shopbutton.encampment, .new-shopbutton.encampment {
    background-image: url('/assets/images/icons/encampment.png');
}

.terrain-button {
    background-repeat: no-repeat;
    height: 30px;
    line-height: 30px;
    padding: 0 10px 0 34px !important;
}

a.button {
    display: inline-block;
    overflow-x: hidden;
}

.main h2.promoted {
    color: rgb(181, 230, 29);
}

.button.train-button {
    padding: 10px 10px;
    margin: 2px 5px;
    width: 200px;
}

.button.train-shop-button {
    padding: 10px 10px;
    margin: 2px 5px;
    width: 90px;
}

.button.train-shop-button.stay {
    width: 150px;
}

#intro {
    margin: 5px 0 10px 0;
}

a.shopbutton:hover,
a.button:hover {
    text-decoration: none!important;
}

.MountainsButton {
  background-image: url("/assets/images/map/Mountains_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.MountainsButton:hover {
	border: #000 double 2px;
}

.PlainsButton {
  background-image: url("/assets/images/map/Plains_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.PlainsButton:hover {
	border: #000 double 2px;
}

.DesertButton {
  background-image: url("/assets/images/map/Desert_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.DesertButton:hover {
	border: #000 double 2px;
}

.ForestButton {
  background-image: url("/assets/images/map/Forest_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.ForestButton:hover {
	border: #000 double 2px;
}

.JungleButton {
  background-image: url("/assets/images/map/Jungle_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.JungleButton:hover {
	border: #000 double 2px;
}

.SnowButton {
	background-image:url("/assets/images/map/Snow_map.png");
	color:#e9c776;
	padding:12px;
	font-size:18px;
	text-align:center;
	font-family:Trajan;
	border: #000 solid 2px;
    text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.SnowButton:hover {
	border: #000 double 2px;
}

.WildernessButton {
  background-image: url("/assets/images/map/Wilderness_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.WildernessButton:hover {
	border: #000 double 2px;
}

.WetlandsButton {
  background-image: url("/images/map/Wetlands_map.png");
  color: #e9c776;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  font-family: Trajan;
  border: #000 solid 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.WetlandsButton:hover {
  border: #000 double 2px;
}

.button:hover,
.submit:hover {
    cursor: pointer;
    background-color: rgb(112, 82, 37);
    color: white;
}

.button:disabled,
.submit:disabled {
    cursor: default;
    color: #444;
    background-color: #aaa;
}

th .button {
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
}

p.feedback-msg {
    padding: 4px;
    border: 1px solid black;
    color: #E4B71B;
}

span.highlight {
    color: #E4B71B;
    font-weight: bold;
}

a.shopbutton.highlight {
    background: white;
    color: black;
}

table.ranking {
    background-color: #141d25;
    /* font-family: Arial, Helvetica, sans-serif;*/
    font-size: 12px;
    color: #f0ecdc;
    width: 100%;
}


.preview-countries img {
    height: 19px;
    vertical-align: middle;
}

.preview-countries h3 {
    margin: 0!important;
    font-weight: bold;
}

div.multi-column-list ul.preview-countries {
    list-style-type: none;
    padding-left: 0;
}

td.hc-message {
    padding: 0;
}

div.hc-message {
    width: 203px;
    overflow-x: hidden;
}

.hc-message img {
    max-width: 203px;
    margin: 0 auto;
    display: block;
    margin: 0 auto;
}

input.tiny_button {
    background-image: url('/assets/images/bg_button.jpg');
    width: 38px;
}

input.small_button {
    background-image: url('/assets/images/bg_button.jpg');
    width: 75px;
}

input.medium_button {
    background-image: url('/assets/images/bg_button.jpg');
    width: 100px;
}

input.large_button {
    background-image: url('/assets/images/bg_button.jpg');
    width: 150px;
}

input.huge_button {
    background-image: url('/assets/images/bg_button.jpg');
    width: 200px;
}

textarea.clan_body {
    width: 700px;
}

select.clan_to {
    width: 220px;
}

input.clan_subject {
    width: 475px;
}

.button.low {
    height: 16px;
    line-height: 16px;
    font-size: 10px;
    padding: 2px;
}

.button.medium {
    width: 90px;
    margin: 2px;
}

.button.wide {
    width: 120px;
    margin: 2px;
}

.button.small {
    width: 70px;
    margin: 2px;
}

input.hq_button {
    background-image: url('/assets/images/bg_button.jpg');
    width: 40px;
}

input.hq_button:disabled {
    background-image: url('/assets/images/bg_button_disabled.jpg');
    width: 40px;
}

.headquarters_nowidth td {
    background-color: #1d2c37;
    border-top: 1px solid rgb(67, 100, 126);
    border-bottom: 1px solid #222;
}

tr.highlight td {
    background-color: black!important;
    /*#CBAD64;*/
    color: #5580A2;
    font-weight: bold;
}

tr.highlight a {
    color: inherit;
}

/*.headquarters_nowidth a {
    color: inherit;
}*/

tr.alternate {
    background-color: #1d2c37;
}

td.error {
    background: #FF0000;
}

p.error {
    color: #263423;
    background: #FF3000;
    padding: 3px;
}

p.disclaimer {
    color: #999;
    padding: 5px 50px;
}

td.menu {
    /*width : 140;  */
    vertical-align: top;
}

.menu a.button {
    width: 115px;
    padding: 10px 0;
    font-size: 18px;
    font-weight: normal;
}

.menu a.button.current {
    background-color: transparent;
    text-align: left;
    color: #d08506;
    padding: 5px 0 0 0;
}

div#age-countdown {
    color: #CBAD64;
}

a {
    color: #d08506;
    text-decoration: none;
}

a:hover {
  color: #d08506;
}

.main h2 {
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.main h3 {
    color: white;
    text-align: left;
    margin: 20px 5px 15px 3px;
    padding-bottom: 4px;
    font-size: 18px;
}

.main a h3 {
    color: inherit;
}

a.register {
    padding-left: 10px;
    font-size: 120%;
}

table.register {
    margin: 0 auto;
}

table.register .button {
    width: 100%;
    padding: 6px;
    font-size: 16px;
    font-weight: bold;
}

ul.submenu {
    margin-left: 25px;
    font-size: 110%;
}

ul.submenu a.selected {
    color: white;
}

.menu ul {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
    padding-left: 15px;
    margin-bottom: 5px;
    list-style-image: url('/assets/images/listimgae.gif');
}

.menu h2 {
    margin: 10px 0 5px 0;
    font-size: 17px;
    color: #CBAD64;
    text-align: left;
}

.menu .highlight {
    color: #EED;
    font-weight: bold;
}

.menu p.small a {
    font-size: 13px;
    text-decoration: underline;
}

.menu p {
    color: #CBAD64;
    margin: 0 0 5px 0;
}

.menu hr {
    width: 100px;
    color: #0a0f13;
    background-color: #0a0f13;
    border: 0px none;
}


/*Country Talk CT*/

table.ct {
    margin: 0 auto;
    width: 777px;
    border: 1px solid #5580A2;
    padding: 1px;
}

tr.ct {
    background: #1d2c37;
}

tr.ct a {
    color: #e9c776;
}

tr.ct a:visited {
    color: #e9c776;
}

tr.ct a:hover {
    color: #ff8c1a;
}

tr.ct_alternate a {
    color: #e9c776;
}

tr.ct_alternate a:visited {
    color: #e9c776;
}

tr.ct_alternate a:hover {
    color: #ff8c1a;
}

tr.ct_alternate {
    background: #1d2c37;
}

td.ct_sender {
    /*background : #cccccd;*/
    border-right: 1px dashed black;
}

tr.ct td {
    border-top: 1px solid #5580A2;
}

tr.ct_alternate td {
    border-top: 1px solid #5580A2;
}


/* messages */

table.inbox th,
table.send_form th {
    background-color: #5580A2;
    /*#355065; /*#D08506;*/
    color: white;
    /*#CEC9B4;*/
}

table.inbox {
    margin: 4px 10px;
    width: 777px;
    border: 1px solid #5580A2;
    padding: 1px;
}

table.inbox td {
    border-bottom: 1px solid #5580A2;
    border-top: 1px solid white;
    padding: 3px 4px 10px 4px!important;
}

table.send_form {
    margin: 4px 10px;
    width: 777px;
    border: 1px solid #5580A2;
    padding: 1px;
}

table.send_form td {
    background: #DADADA;
    color: black;
}

tr.msg {
    background: #FFFFFF;
    color: black;
}

tr.msg a {
    color: blue;
}

tr.msg a:visited {
    color: #000055;
}

tr.msg_alternate a {
    color: blue;
}

tr.msg_alternate a:visited {
    color: #000055;
}

input.save_button {
    background: #5B5B5B;
    color: #FFFFFF;
    width: 39px;
    height: 20px;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-top-color: #000000;
    border-left-color: #000000;
    border-right-color: #000000;
    border-bottom-color: #000000;
}

tr.msg_alternate {
    background: #DADADA;
    color: black;
}

td.msg_international {
    background: #F3725F;
    color: #810!important;
    /*background : #cdcccc;*/
    border-right: 1px dashed black;
}

td.msg_international a {
    color: #810!important;
}

td.msg_national {
    color: #160!important;
    border-right: 1px dashed black;
    background: #cccdcc;
}

td.msg_national a {
    color: #160!important;
}

td.msg_system {
    /*background : #cccccd;*/
    border-right: 1px dashed black;
}

td.msg_sender {
    /*background : #cccccd;*/
    border-right: 1px dashed black;
}

td.msg_system a {
    color: black;
}

a.menu_red {
    /*color : #FBF7A8 ; */
    color: red;
    font-weight: bold;
}

table#page {
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

table.main {
    height: 700px;
    position: relative;
    padding: 0;
    color: #e9c776;
    table-layout: fixed;
}

img.signalflag {
    float: right;
    vertical-align: middle;
}

table#shopbuttons {
    width: 320px;
    margin: 0 auto 15px auto;
}

table#shopbuttons td {
    text-align: center;
    padding: 0;
}

td.main {
    vertical-align: top;
    text-align: center;
}

select.travel {
    width: 190px;
}

table.subtable {
    width: 100%;
    background-color: #141d25;
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-size: 14px;
    color: #f0ecdc;
    margin: 0;
    padding: 0;
}

td.subtable_header {
    font-size: 16px;
    background-color: #141d25;
    /*font-family: Arial, Helvetica, sans-serif;*/
    color: white;
    margin: 0;
    padding: 4px 4px 5px 4px!important;
}

.symbol {
  height: 26.6px;
  width: 37.8px;
  vertical-align: middle;
}

.icon {
    vertical-align: middle;
}

img.userbaricon {
    height: 100%;
    width: 100%;
}

.unit-icon {
    height: 30px;
    width: 30px;
}

span.bonus {
    font-weight: bold
}

span.bonus.positive {
    color: lime;
}

span.bonus.negative {
    color: red;
}

span.bonus.zero {
    display: none;
}

p.location {
    text-align: left;
    border-left: 1px solid #141D25;
    padding: 0 0 4px 6px;
    margin-bottom: 6px;
}

p.location span.label {
    display: inline-block;
    width: 75px;
}

p.location img {
    margin-left: 10px;
}

table#userlist {
    vertical-align: top;
}


/* encampment income table */

table#camp-income {
    margin-bottom: 10px;
}

table#camp-income td.label {
    width: 250px;
}

table#camp-income td.value {
    width: 50px;
}

table#camp-income td.explain {
    width: 450px;
    vertical-align: top;
    color: #999;
}

.loginlabel {
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-size: 14px;
    color: #FFFFFF;
}

.bluedash {
    color: #243745;
    font-size: 13px;
}

.logintext {
    font-size: 13px
}

.loginlinks {
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-size: 13px;
}

.postedby {
    color: #3E5D75
}

#login {
    width: 480px;
    border: 2px solid #3f5f78;
    padding: 8px 8px 4px 8px;
    margin-bottom: 3px;
    background-color: #3f5f78;
    margin-bottom: 10px;
}

#homepage-content {
    width: 500px;
    margin: 0 auto;
}

#homepage-content h1 {
    margin: 15px 0 5px 0;
}

#homepage-content h3 {
    margin: 10px 0 10px 0;
    color: #ecb700;
}

#homepage-content h2 {
    margin: 10px 0 10px 0;
    color: #ecb700;
    text-align: left;
}

#read-more {
    display: none;
}

#facebook-like {
    position: absolute;
    left: 880px;
    top: 120px;
}

.public #facebook-like {
    position: absolute;
    left: 780px;
    top: 120px;
}


/* news messages */

table.newsitem {
    width: 100%;
    /*background-color: #141d25;  */
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-size: 12px;
    color: #f0ecdc;
    margin: 0;
    padding: 0;
}


td.news_title {
    font-weight: bold;
    font-size: 16px;
}

td.news_footer {
    font-style: italic;
    color: #CBAD64;
}

table.headquarters {
    width: 400px;
    border-width: 2px;
    border-color: #000000;
    border-style: solid;
}

table.headquarters th,
table.headquarters_nowidth th,
table.horizontal_table th {
    background-color: #5580A2;
    /*#355065; /*#D08506;*/
    color: #1d2c37;
    /*#CEC9B4;*/
    line-height: 140%;
    font-weight: bold;
    border-bottom: 1px solid white;
}

table.horizontal_table tr.hidden {
    display: none;
}

table.headquarters_nowidth th a {
    color: white;
}

ul.page_browser {
    list-style-type: none;
}

ul.page_browser li {
    float: left;
}

ul.page_browser a {
    display: block;
    border: 1px solid #355065;
    color: #000000;
    background-color: #5580A2;
    font-size: 14px;
    width: 24px;
    text-decoration: none;
    margin: 4px 2px;
    padding: 2px 2px;
    text-align: center;
}

ul.page_browser a.selected {
    background-color: #355065;
    color: #ffffff;
}

ul.page_browser a:hover {
    background-color: #355065;
    color: #ffffff;
}

img.location {
    border: 1px solid #CBAD64;
    padding: 1px;
    margin-bottom: 5px;
}

table.headquarters_nowidth {
    margin-bottom: 10px;
    border: 1px solid rgb(160, 185, 205);
    margin-top: 5px;
}

td.hq_header {
    font-size: 16px;
    border-bottom-width: 1px;
    border-bottom-color: #000000;
    border-bottom-style: solid;
}

td.hq_subject {
    border-right-width: 1px;
    border-right-color: #000000;
    border-right-style: solid;
}

table.main table td {
    padding: 2px 4px;
}

p.injured {
    color: #a00;
}

p.footnote {
    padding-top: 10px;
    color: #ccc;
    font-style: italic;
}


/* questionary */

table.itemgroup {
    width: 100%;
    margin-bottom: 5px;
}

table.itemgroup td {
    background-color: #446882;
    padding: 3px 3px 3px 5px;
}

table.itemgroup td.option,
table.itemgroup th.option {
    text-align: center;
}

table.itemgroup td textarea {
    width: 99%;
    height: 60px;
    background-color: #26211C;
    border: 1px solid #000000;
    color: #E6EBEE;
    font-size: 12px;
}

table.itemgroup input.text {
    width: 99%;
}

table.itemgroup th {
    text-align: left;
    padding: 2px 2px 2px 5px;
    background-color: #141D25;
}

table.itemgroup th.question,
table.itemgroup td.question {
    width: 50%;
}

table.itemgroup th.item_nr,
table.itemgroup td.item_nr {
    width: 5%;
}

div.questionnaire_note {
    padding: 4px;
    background-color: #446882;
    border: 1px solid #537E9E;
    margin-bottom: 5px;
}

/* armory */

.CenteralizeTable {
    width: auto;
    margin-left: auto;
    margin-right: auto
}

.map-popup .city-info .name {
	float: left;
	font-size: 24px;
	font-family: MedievalSharp;
	color: #e9c776;
	margin-top: 4px;
	padding-left: 4px;
}

.map-popup .city-info .flag {
	float: right;
	margin-top: 4px;
	padding-right: 4px;
}

.map-popup .city-info {
	margin: 0px;
}

.map-popup .description {
	color: #e9c776;
	font-size: 15px;
	font-style: italic;
	font-family: MedievalSharp;
	/*margin: 0px 0px 0px 4px;*/
}

.map-popup .terrain {
	font-size: 16px;
	margin-left: -4px;
}

.map-popup .main-window {
	padding-bottom: 6px;
	border-bottom: 1px #e9c776 solid;
	margin-left: -10px;
	margin-right: -10px;
	margin-top: -10px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    padding-left: 4px;
    padding-right: 4px;
}

div.hero {
    float: left;
    width: 150px;
    line-height: 14px;
    border: 1px solid black;
    height: 318px;
    margin-left: 7px;
    border-radius: 5px;
}

div.hero h3 {
    height: 30px;
    width: 144px;
    background-color: #355065;
    margin: 0px;
    padding: 3px 3px;
    border-bottom: 1px solid black;
    font-size: 15px;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

div.hero a h3 {
    color: white;
}

div.hero a img {
    border: none;
}

div.hero table {
    line-height: 115%;
}

div.hero a:hover {
    cursor: hand;
    text-decoration: none!important;
}

div.hero h3 img {
    vertical-align: middle;
    margin-right: 5px;
}

div.hero_selected h3 {
    background-color: #CBAD64;
    color: #222!important;
}

div.hero_selected {
    background-color: #355065;
}

div.hero p {
    padding: 5px;
    color: #999;
}

div.hero th {
    text-align: left;
    padding: 1px 2px;
    font-size: 13px;
    font-weight: normal;
}

div.hero td {
    text-align: left;
    padding: 1px 0px;
    font-size: 13px;
}

div.hero tr.separator th,
div.hero tr.separator td {
    border-bottom: 1px dashed black;
    padding-bottom: 5px;
}

div.tabs {
    padding: 0px;
    border-bottom: 1px #000 solid;
    display: flex;
    justify-content: space-between;
}

    div.tabs .tab {
        color: white;
        font-family: Trajan;
        display: inline-block;
        /*width: 160px;*/
        position: relative;
        top: 1px;
        border: 1px solid black;
        border-bottom: 0px;
        padding: 5px 10px 0px 10px;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
    }

div.tabs .selected {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
}

div.tabs .tab:hover {
    text-decoration: none!important;
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
}

div.tabs-body {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    min-height: 300px;
    padding: 1px 5px 5px 5px;
    text-align: center;
}

div.tabs-body h3 {
    text-align: center;
}

div.tabs-footer {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    height: 10px;
    width: 100%;
    border-left: 1px #000 solid;
    border-right: 1px #000 solid;
}


/* 355065 */

div.tab-menu {
    height: 26px;
    margin-bottom: 15px;
    overflow: hidden;
    border-bottom: 4px solid #355065;
}

div.tab-menu ul li a {
    float: left;
    border: 1px solid #355065;
    background-color: transparent;
    color: #000000;
    padding: 6px 6px;
    margin-right: 2px;
    vertical-align: middle;
    text-decoration: none;
}

div.tab-menu ul li {
    list-style-type: none;
}

div.tab-menu ul li a.current {
    background-color: #355065;
    color: #ffffff;
}

div.tab-menu ul li a.alert,
div.side .menu ul a.alert {
    color: #ffffff;
    background-color: #dd0000;
}

div.tab-menu ul li a:hover {
    text-decoration: none;
    text-decoration: none!important;
    background-color: #355065;
    color: #000000;
    background-image: none;
    color: #ffffff;
}

div.tab-menu ul li a.current:hover {
    background-color: #355065;
    color: #ffffff;
}

p.alert {
    color: #ffffff;
    background-color: #dd0000;
    padding: 4px;
    margin-bottom: 15px;
}

h4 {
    margin: 4px 0px;
    color: #CBAD64;
    font-size: 18px;
    font-weight: normal;
}

h5 {
    margin: 8px 0px 2px 0px;
    color: #CBAD64;
    font-size: 16px;
    font-weight: normal;
}

table.merchant-trading td.selected {
    border: 2px solid white;
}

table.merchant-trading {
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
}

table.merchant-trading td {
    text-align: center;
    font-size: 9px;
}

table.merchant-trading td.dots {
    text-align: center;
    font-size: 25px;
}

.main h4 {
    margin-top: 20px;
}


/* tutorial */

div.tutorial {
    padding: 4px 10px 4px 10px;
    margin: 0 0 3px 0;
    border: 1px solid #CBAD64;
    background-color: #537E9E;
}

div.tutorial-header {
    background-color: #CBAD64;
    color: #222;
    padding: 4px 10px 4px 10px;
    margin-bottom: 0px;
}

div#tutorial-more {
    display: none;
    background: black;
    color: white;
}

div.tutorial h2 {
    font-size: 16px;
    margin-top: 2px;
    text-transform: none;
    color: #222;
    font-weight: normal;
    margin-bottom: 0px;
}

div.tutorial .skip {
    float: right;
    width: 80px;
}

form {
    padding: 0px;
    margin: 0px;
}

div.tutorial p {
    margin: 0 0 10px 0;
}

.clearfix {
    clear: both;
}


/*
	New CSS
*/

/*
	Background and basic properties of the website layout
*/

html,
body {
  margin: 0;
  height: 100%;
  background-image: url("/assets/images/ui/TLK_Background2.png"), url("/assets/images/ui/background_repeat.png");
  background-position: center top, center top;
  background-repeat: no-repeat, repeat-y;
  background-size: cover;
  background-color: #000000;
  /*font-family: Trajan;*/
  color: #FFFFFF;
  scroll-behavior: smooth;
  scrollbar-color: #4f1e09;
  touch-action: manipulation;
}

/* custom scroll bar */
/* width */
::-webkit-scrollbar {
width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #53210a;
background-image: url(/assets/images/ui/Column_Sidebar_Top.png), url(/assets/images/ui/Column_Sidebar_Top.png), url(/assets/images/ui/Column_Sidebar_Bottom.png), url(/assets/images/ui/Column_Sidebar_Bottom.png), url(/assets/images/ui/Column_Sidebar_Tile.png), url(/assets/images/ui/Column_Sidebar_Tile.png), url(/assets/images/ui/TLK_Menu_Background_Tile.png);
background-position: top left, top right, bottom left, bottom right, top left, top right, top left;
background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-y, repeat-y, repeat;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
cursor: pointer;
}

#container {
    display: block;
    margin: 0 auto;
    max-width: 95%;
}

.LogoLink {
    display: block;
}


/*
	Dynamic Columns
*/

div.sys_notices {
    position: inherit;
    width: 100%;
}


/*
	Looks of the UI
*/

.PageTitle {
    text-align: center;
    color: #e9c776;
    margin-bottom: 1rem;
}

.CentralizedObject {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.EventTable {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	border: 2px solid #e9c776;
	padding: 2px;
}
.EventTable .even {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	padding: 2px;
}
.EventTable .odd {
	padding: 2px;
}



.ClickableTextNoLayout a {
    font-family: MedievalSharp;
    color: #e9c776;
    text-decoration: none;
    font-size: 20px;
}

.ClickableTextNoLayout a:hover {
    color: #ff8c1a;
}


/*
	Navigation Menu
*/

.Announcement {
    font-family: Trajan;
    color: #e9c776;
    padding: 2px;
    margin: 10px 2px;
    font-size: 0.8rem;
}

    .Announcement > a {
        color: #e9c776;
        text-decoration: none;
    }

        .Announcement > a:hover {
            color: #ff8c1a;
        }

.SubmenuTitle ~ ul {
    padding: 0px;
    margin-bottom: 0;
}

.GoldenArrow {
    float: right;
    transform: translate(-15px, 0px);
    cursor: pointer;
}

.LoggedInAgeTimer,
.LoggedOutAgeTimer {
    text-align: center;
    color: #ffffff;
    padding-top: 7px;
    font-size: 18px;
}

.LoggedOutAgeTimer {
    padding-top: 20px;
}

.Seperator {
    width: 180px;
    margin-left: -1px;
    padding-top: 10px;
}


/*
	Buttons
*/


/*
Alerts
*/

.NegativeAlert {
	position:relative;
    padding: 0;
    background-color: #ff1a1a;
    text-align: center !important;
    margin: 0;
    z-index:100;
}

.PositiveAlert {
	position:relative;
    padding: 0;
    background-color: #6ba301;
    text-align: center !important;
    margin: 0;
    z-index:100;
}

.NoAlert {
    padding: 0;
    height: 20px;
    margin: 0;
}


/*
Features CSS
*/

.progressBar {
    height: 30px;
}

.percentage {
    text-align: center;
}

.textCenter {
    position: relative;
}

.glow {
    text-shadow: 0 0 4px #4d2600;
    top: 15%;
}

.outter {
    width: 100%;
    height: 25px;
    border: solid;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.inner {
    text-align: center;
    display: table-cell;
    height: 25px;
    border-right: solid 1px #000;
    position: absolute;
    background: #ffb76b;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffb76b 0%, #ffa73d 49%, #ff7c00 77%, #ff7f04 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffb76b 0%, #ffa73d 49%, #ff7c00 77%, #ff7f04 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffb76b 0%, #ffa73d 49%, #ff7c00 77%, #ff7f04 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb76b', endColorstr='#ff7f04', GradientType=0);
    /* IE6-9 */
}

.center {
    text-align: center !important;
}

.centerDonate {
    text-align: center;
    margin: 0 auto;
}

.centerTable {
    margin: 0 auto;
}

.InputText {
    background-color: white;
    color: black;
}

.IconButton {
    color: white;
    background: none;
    border: none;
}

    .IconButton:hover {
        color: #ff8c1a
    }

.SelectableItem {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    font-family: MedievalSharp;
    border: 1px solid #e9c776;
    color: #e9c776;
    display: inline-block;
    text-align: center;
    user-select: none;
    font-size: 20px;
}

.SelectableItem:hover {
    color: #ff8c1a;
    border: 1px solid #ff8c1a;
    cursor: pointer;
}

.Lightbackground {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png") !important;
}

.PageButton {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    font-family: MedievalSharp;
    color: #e9c776;
    border: 1px solid #e9c776;
    text-align: center;
    vertical-align: middle;
    width: 48px;
    height: 48px;
    user-select: none;
}

.Clickable:hover {
    color: #ff8c1a;
    border: 1px solid #ff8c1a;
    cursor: pointer;
}

.Pointer {
    cursor: pointer;
}


/*
XBBCode css
*/

.xbbcode-b {
    font-weight: bold;
}

.xbbcode-blockquote {
    position: relative;
    background: #fff6c7;
    margin: .25em 0;
    border: 1px solid #aaa;
    padding: .25em
}

.xbbcode-blockquote cite {
    font-weight: 700;
    display: block;
    font-size: 1em;
    border-bottom: 1px solid #aaa
}

.xbbcode-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.xbbcode-code:before {
    position: absolute;
    content: 'Code:';
    top: -1.35em;
    left: 0;
    color: #fff;
}

.xbbcode-code {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #111;
    margin-top: 1.5em;
    position: relative;
    background: #eee;
    border: 1px solid #aaa;
    white-space: pre;
    padding: .25em
}

.ie6 .xbbcode-code,
.ie7 .xbbcode-code {
    margin-top: 0
}

.xbbcode-code,
.xbbcode-code:before {
    display: block;
    text-align: left
}

.xbbcode-i {
    font-style: italic;
}

.xbbcode-justify {
    display: block;
    text-align: justify;
}

.xbbcode-left {
    display: block;
    text-align: left;
}

.xbbcode-right {
    display: block;
    text-align: right;
}

.xbbcode-s {
    text-decoration: line-through;
}

.xbbcode-size-1 {
    font-size: x-small;
}

.xbbcode-size-2 {
    font-size: small;
}

.xbbcode-size-3 {
    font-size: medium;
}

.xbbcode-size-4 {
    font-size: large;
}

.xbbcode-size-5 {
    font-size: x-large;
}

.xbbcode-size-6 {
    font-size: xx-large;
}

.xbbcode-size-7 {
    font-size: xx-large;
}

.xbbcode-size-8 {
    font-size: xx-large;
}

.xbbcode-u {
    text-decoration: underline;
}

.xbbcode-table {
    border-collapse: collapse;
}

.xbbcode-table,
.xbbcode-th,
.xbbcode-td {
    border: 1px solid #e9c776;
}

.xbbcode-ol,
.xbbcode-ul {
    display: inline-grid;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0
}

/*/tutorial glow*/

.tutorialglow {
    color: hsla(120, 100%, 25%, 1);
    -webkit-animation: neon 1.5s ease-in-out infinite alternate;
    -moz-animation: neon 1.5s ease-in-out infinite alternate;
    animation: neon 1.5s ease-in-out infinite alternate;
}

/*
li.SubmenuItem.tutorial a:hover {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
  color:white;
}
*/

@keyframes neon {
    from {
        color: red;
        text-shadow: 0 0 20px #fffffe, 0 0 30px #fffffe, 0 0 40px #fffffe, 0 0 50px #dd0000, 0 0 80px #dd0000, 0 0 90px #dd0000, 0 0 110px #dd0000, 0 0 160px #dd0000;
    }
    to {
        color: green;
        text-shadow: 0 0 5px #fffffe, 0 0 10px #fffffe, 0 0 15px #fffffe, 0 0 20px #dd0000, 0 0 35px #dd0000, 0 0 40px #dd0000, 0 0 50px #dd0000, 0 0 75px #dd0000;
    }
}

.Tutcontainer {
    padding: 5px;
}

i.arrow.down {
    float: right;
}

.arrow-down:after {
    content: '\25bc';
    padding-left: 15px;
    font-size: 8px;
}

.tutorialheader {
    text-align: left;
    font-size: 130%;
    background-color: #355065;
    color: #d08506;
    padding: 10px;
    border: 1px solid black;
}

.tutorialcontent {
    text-align: left;
    padding: 10px;
    /*    background-color: #36414a;*/
    border: 1px solid black;
    margin-bottom: 5px;
}


.back-button {
  font-size: 1.3rem;
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  border: 2px solid #e9c776;
  color: #e9c776;
  font-family: MedievalSharp;
  padding: 8px 10px;
  cursor: pointer;
  margin: -5px 20px 20px;
  display: inline-block;
  position: absolute;
}

.back-button i {
  padding-right: 5px;
}

.back-button:hover {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
  text-decoration: none;
}

.back-button:active {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}


/*Responisve css*/

@media (max-width: 768px) {
    html {
        font-size: 14px;
    }

    html,
    body {
        background-image: url('/assets/images/ui/TLK_Menu_Background_Tile_Dark.png');
        background-repeat: repeat;
    }
    div.tabs {
        overflow-x: auto;
        overflow-y: hidden;
    }
    #container {
        width: 100%;
        min-width: 100%;
    }
    .SubmenuTitle {
        height: 50px;
        background-size: 100% 60px;
    }
    a.LogoLink {
        width: 37% !important;
        height: 110px !important;
        float: left;
        margin-left: 0px !important;
    }
    a.LogoLink img {
        width: auto;
        height: 85px;
        margin: 0px auto;
        display: block;
    }

/*    #userbar {
        height: auto;
        font-size: 1.5rem;
        background-image: url("/assets/images/ui/Menu_Footer_Middle.png");
        background-position: bottom center;
        background-repeat: repeat-x;
    }
  

    #userbar .usermenu a i {
        font-size: 1em;
    }
*/
    a.logout {
        margin-left: 0;
        padding: 24px;
    }
    img.icon {
        height: 40px;
        width: auto;
        padding: 5px;
        margin: 5px;
    }
    a.sidebarmenu.ng-scope {
        margin-left: 10px;
    }
    .LoggedInAgeTimer,
    .LoggedOutAgeTimer {
        font-size: 80%;
        padding-top: 7px;
    }
    table#cityTable i {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        margin-right: 10px;
    }
    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    span.sign {
        border: 1px solid #d08506;
    }
    p.subtable_header {
        background-color: #141d25;
        padding: 15px;
        border: 1px solid black;
    }
    table#cityTable {
        font-size: 22px;
    }
    #shopbuttons td p {
        display: none;
    }
    table#shopbuttons {
        width: 100%;
        margin: 0 auto;
    }
    a.shopbutton {
        width: 60%;
        height: 50px;
        background-position: left center;
        background-size: contain;
        padding: 2px 6px 2px 75px;
        /* color: white; */
        font-size: 15px;
        line-height: 50px;
    }
    table#TravelTable {
        margin-top: 15px;
        padding-top: 5px;
    }
    table#TravelTable tr:nth-child(3) td {
        /* width: 50%; */
        float: left;
        display: block;
    }
    table#TravelTable tr:nth-child(4) td {
        float: right;
        margin: 2px;
        line-height: 40px;
        font-size: 18px;
    }
    table#TravelTable tr:nth-child(3) {
        width: 50%;
        float: left;
        display: block;
    }
    table#TravelTable tr:nth-child(4) {
        width: 50%;
        float: left;
        display: block;
    }
    td.subtable_header {
        font-size: 20px;
    }
    select.travel {
        width: 100%;
        font-size: 18px;
        padding: 3px;
        height: 50px;
    }
    table#TravelTable input.button {
        font-size: 20px;
        font-weight: bold;
        padding: 8px 25px;
        margin-right: 15px;
    }
    table#TravelTable a {
        line-height: 40px;
        font-size: 20px;
    }
    table#CommanderInfo,
    table#userlist {
        font-size: 20px;
        display: none;
        line-height: 30px;
    }
    .ageStats {
        width: 132px;
        height: 60px;
        float: left;
        /* position: absolute; */
        margin: 20px;
        /* right: -190px; */
        line-height: 25px;
        background-color: rgba(0, 0, 0, 0.4);
        border: 1px solid #5580A2;
        -moz-box-shadow: inset 0 0 10px #000000;
        -webkit-box-shadow: inset 0 0 10px #000000;
        box-shadow: inset 0 0 10px #000000;
    }
     .usermenu1 {
        display: block;
        background-image: url("/assets/images/ui/Menu_Footer_Middle.png");
        width: 100%;
        background-position: bottom center;
        background-repeat: repeat-x;
        height: 100px !important;
    }
    .userm {
    	background: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
        padding: 15px;
        font-size: 28px;
        height: 50px;
        display: inline-block;
        width: 100%;
    }
    .userm:hover {
    	background: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    }
    div#clockmob {
        float: left;
        margin: 20px;
    }
    table.headquarters,
    table.floatLeftTable {
        width: 100%;
    }
    table.floatLeftTable > tbody > tr > td {
        padding: 0px;
        width: 100%;
        float: left;
    }
    table.floatLeftTable table,
    table.floatLeftTable textarea {
        width: 100%;
    }
    table.inbox {
        width: 100% !important;
        margin: 0px;
    }
    div.tabs a.tab {
        margin: -1px;
        width: 25%;
        height: 50px;
        vertical-align: bottom;
    }
    div.tabs {
        width: 100%;
    }
    .arrow-down:after {
        content: '\25bc';
        padding-left: 15px;
        font-size: 8px;
    }
    .dropdown-emulator-select-btn {
        font-size: 17.5px;
        padding: 4px 5px;
        color: #E6EBEE;
        background-color: #355065;
        border: 1px solid #355065;
        border: 1px solid #000000;
        top: 2px;
        width: 100%;
        position: relative;
        text-decoration: none;
    }
    .dropdown-emulator-select-btn:hover {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    .dropdown-emulator-select-btn:active {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    ul#grpChkBox {
        list-style: none;
        text-align: left;
        background-color: #355065;
        border: 1px solid #355065;
        border: 1px solid #000000;
        padding: 0px;
        position: absolute;
        display: none;
        border: 1px solid #d08506;
        font-size: 25px;
        line-height: 32px;
    }
    .GeneralTable input {
        font-size: 17.5px;
    }
    .GeneralTable select {
        height: 30px;
        font-size: 17.5px;
    }
    table.send_form {
        width: 100%;
        margin: 4px 0;
    }
    input.clan_subject {
        width: 100%;
    }
    select.clan_to {
        width: 100%;
    }
    textarea.clan_body {
        width: 100%;
    }
    table.ct {
        width: 100%;
    }
    .group div {
        width: 50%;
        float: left;
        text-align: right;
        padding-right: 10px;
    }
    .group select,
    .group input {
        width: 20%;
    }
    .forum-subsection img.symbol {
        height: 20px;
    }
    .forum-topic img.symbol {
        height: 20px;
    }
    .forum-post-message img {
        max-width: 95%;
    }
    table.forum-subsection div.reply-user {
        height: auto !important;
    }
    div.forum-new-post {
        width: auto !important;
    }
    div.forum-new-post input.title {
        width: 100% !important;
    }
    div.forum-new-post textarea {
        width: 100% !important;
    }
    table img.flag.symbol {
        height: 20px;
        margin-left: 5px;
    }
    table i {
        padding: 0;
        border: 0;
        margin: 0;
        float: none;
    }
    select[name=nation_id] {
        height: 35px;
        font-size: 18px;
    }
    input.button[name=choose_nation] {
        font-size: 25px;
    }
    input[name=heroname] {
        width: 50%;
        float: left;
    }
    select[name=nation] {
        max-width: 80px;
    }
    input[name="name"] {
        max-width: 80px;
    }
    .usermenu1 img.rank.symbol {
        display: none;
    }
    .logoutmenu {
        width: 100%;
        height: auto;
        display: block;
    }
    .back-button {
      position: static;
    }
}

.sfplannerbyazzy label {
    font-family: MedievalSharp;
    font-size: 20px;
    color: #e9c776;
    text-align: center;
}


fieldset.sfinstr {
    float: left;
}

fieldset.sfplan {
    float: right;
}

fieldset.sftime {
    float: left;
}

.sfplannerbyazzy button {

    font-size: 12px;
    padding: 5px 5px;
    color: #E6EBEE;
    background-color: #355065;
    /* border: 1px solid #355065; */
    border: 1px solid #000000;

}

form.hcplanningtools fieldset textarea {
    width: 100%;
    height: 100px;
    font-size: 12px;
    padding: 5px 5px;
    color: #E6EBEE;
    background-color: #355065;
    /* border: 1px solid #355065; */
    border: 1px solid #000000;

}

form.hcplanningtools fieldset {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png"), 
        url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    background-position: top left, top left;
    background-repeat: repeat-x, repeat;
    background-size: 28px, auto;
    width: 48%;
    margin: 1%;
    display: block;
    border: none;
    padding: 0;
}

form.hcplanningtools {
    font-size: 16px;
}

.sfplane {
    padding: 10px;
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    background-position: top left, top left;
    background-repeat: repeat;
    background-size: 28px, auto;
    padding: 10px;
}

.sftimee {
    padding: 10px;
    text-align: center;
    font-size: 35px;
}

.sfinstre {
    text-align: center;
    margin: 0 auto;
}

.singups {
    text-align: center;
}

.leadlist {
    text-align: center;
}

.leadlist table {
    width: 100%;
}

textarea#msgbody {
    margin: 10px;
    padding: 10px;
    width: 300px;
    height: 50px;
    /* display: block; */
}

form.sfplanform {
    /* width: 100%; */
    clear: left;
    margin: 5px;
}
.sfplane table {
    width: 100%;
}

.heading {
    font-family: MedievalSharp;
    font-size: 20px;
    color: #e9c776;
    text-align: center;
}


.newmarket {
    text-align: center;
    margin: auto;
	padding: 12px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    background-color: black;
    color: #e9c776;
    text-align: center;
    border-radius: 8px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
	opacity: 0.90;
    visibility: visible;
}

.tooltip .angulartooltip {
		width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    color: #e9c776;
    text-align: center;
    border: #000 solid 1px;
    border-radius: 8px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
}

div#RankingsTable td {
    text-align: left;
    padding-left: 5px;
}

div#RankingsTable th {
    text-align: left;
}

div#RankingsTable {
    margin: 5px;
}

div#RankingsTable table {
    border: 1px solid #e9c776;
}

div#RankingsTable img {
    height: 20px;
    padding: 5px;
}

.accordion {
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    color: #e9c776;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    border: 1px black solid;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4s;
}

.accordion:hover {
	border: 2px #e9c776 dashed;
}

.accordion:after {
    content: url("/assets/images/ui/GoldArrow_Down.png");
    display: inline-block;
    float: right;
    margin-left: 5px;
}

.MoveModal {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	border: 3px ridge #e9c776;
	width: 40%;
	text-align:center;
	margin:auto;
	padding:20px;
	height:auto;
}
.MoveModal .Title {
	display:inline-block;
	padding:0px 0px 10px 10px;
	font-family:MedievalSharp;
	color:#e9c776;
	font-size:24px;
}
.MoveModal .Close {
	display:inline-block;
	float:right;
	padding:0px 10px 10px 10px;
	font-family:Trajan;
	color:#e9c776;
	font-size:24px;
	font-weight:bold;
	cursor:pointer;
}
.MoveModal .Error {
	display:inline-block;
	padding:0px 10px 10px 10px;
	font-family:MedievalSharp;
	color:#cc0000;
	font-size:24px;
}
.MoveModal .Moves {
	display:inline-block;
	font-family:Trajan;
	font-size:12px;
}
.MoveModal .Toggle {
	cursor:pointer;
	margin:auto;
	float:right;
	color:#e9c776;
	font-size:14px;
	font-family:Trajan;
}

.ForceScroll{
    overflow: auto;
}
.Height300{
    max-height: 300px;
}
.Width600{
    max-width: 600px;
}

.loadTitle {
	position:relative;
	width:30%;
	top:15%;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	text-align: center;
   color: #e9c776;
   font-size:30px;
   padding:25px;
   margin:auto;
   border: 1px solid #000;
   z-index:1000;
}

.CityCenterStatsBox {
	font-family:Trajan;
	font-size: 0.75rem;
	width:95%;
	background-image:url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	text-align:center;
	padding:8px;
	border:1px solid #e9c776;
	position:relative;
	margin: 0rem auto 1rem auto;
}

.polaroid {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  border: 1px solid #e9c776;
  cursor: pointer;
}

.polaroid img {
	width:100%;
	height:auto;
}

.polaroid .text {
	font-family:Trajan;
	color:#e9c776;
  text-align: center;
  padding: 2px;
}

.CityCenterMovableCities {
	display:inline-block;
	margin:10px;
	margin-bottom:15px;
}

.buttonV2 {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Normal.png"), url("/assets/images/ui/Resizable_Button1_R-End_Normal.png"), url("/assets/images/ui/Resizable_Button1_Tile_Normal.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	padding:10px;
	font-size:16px;
	font-family: Trajan;
	color:#e9c776;
	cursor:pointer;
	border: 0px;
}

.buttonV2:hover {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Hover.png"), url("/assets/images/ui/Resizable_Button1_R-End_Hover.png"), url("/assets/images/ui/Resizable_Button1_Tile_Hover.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	color:#fff;
}

.buttonV2:active {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_R-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_Tile_Clicked.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	color:#fff;
}
.buttonV2.disabled {
	background-image: url("/assets/images/ui/Resizable_Button1_L-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_R-End_Clicked.png"), url("/assets/images/ui/Resizable_Button1_Tile_Clicked.png");
	background-position: left, right, center;
	background-repeat: no-repeat, no-repeat, repeat-x;
	background-size:contain, contain, contain;
	color:#fff;
	-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}




.labelV2 {
	display:inline-block;
	border: #e9c776 solid 1px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	font-family:Trajan;
	text-align:center;
	padding:2px 12px 2px 12px;
	font-size:12px;
	width:8px;
	overflow:hidden;
	cursor:default;
}
.dropdownV2 {
	border: #e9c776 solid 1px;
	-moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
	background-image: url("/assets/images/ui/GoldArrow_Down.png"), url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	font-family:Trajan;
	text-align:center;
	background-repeat: no-repeat, repeat;
	background-position:center right, top left;
	cursor:pointer;
	padding-right:10px;
}
.dropdownContainer {
	position: relative;
    display: inline-block;
}
.dropdownContent {
    position: absolute;
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    width:100%;
    border:#000 1px solid;
    z-index: 1;
    transition: all 800ms ease;
}
.dropdownContent div {
    color: #e9c776;
	font-family:Trajan;
    display: block;
    font-size:12px;
}
.dropdownContent div:hover {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}
.ArmouryPointsContainer {
	text-align:center;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	margin:auto;
	padding: 15px;
	font-family:Trajan;
	font-size:14px;
}
.ArmouryPointsContainer .ArrowLeft {
	transform: rotate(180deg);
	cursor: pointer;
    vertical-align: top;
}
.ArmouryPointsContainer .ArrowRight {
	cursor:pointer;
    vertical-align: top;
}
.ArmouryPointsContainer .SemiTrans {
	opacity: 0.3;
	cursor:not-allowed;
}
.ArmouryPointsContainer .Title {
	font-family:MedievalSharp;
	font-size:24px;
	text-align:center;
	color: #e9c776;
}
.ArmouryPointsContainer .Price {
	font-size:10px;
	color: #e9c776;
	white-space:normal !important;
    text-align: left;
}
.tdAlignCenter td, .tdAlignCenter th {
	text-align:center;
}
.ArmouryPointsContainer .increasedSize {
	margin-bottom:-3px;
	height:34px;
	width:34px;
}
.ArmouryPointsContainer .nowrap td{
 white-space:nowrap;
}

.ArmourySkillsExplanation .Title {
	display:inline-block;
	font-size:24px;
	color:#fff;
	font-family:Trajan;
	padding:20px 0px 0px 20px;
	border-bottom:3px solid #e9c776;
}
.ArmourySkillsExplanation .body {
 padding-top:3px;
}
.AdminPollContainer {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	border:1px solid #e9c776;
	padding:15px;
	margin:auto;
	width:80%;
}
.AdminPollContainer .Title {
	font-size:24px;
	font-family:Trajan;
	color:#e9c776;
	padding:10px;
	text-align:center;
	display:block;
}
.AdminPollContainer .SubTitle {
	font-size:16px;
	font-family:Trajan;
	color:#fff;
	padding:10px;
	display:inline-block;
}
.AdminPollContainer .Special {
	font-size:24px;
	font-weight:bold;
	font-family:Trajan;
	color:#e9c776;
	padding:10px;
	float:right;
	cursor:pointer;
}
.AdminPollContainer .Question {
	font-size:16px;
	font-family:MedievalSharp;
	color:#e9c776;
	padding:10px;
	background-image:url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	border:2px solid #fff;
	display:inline-block;
	width:100%;
}
.AdminPollContainer .Answer {
	font-size:12px;
	color:#fff;
	padding:4px;
	display:inline-block;
	width:100%;
}
.AdminChangeLog {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	border:1px solid #e9c776;
	padding:15px;
	margin:auto;
	width:80%;
}
.AdminChangeLog .Title {
	font-size:24px;
	font-family:Trajan;
	color:#e9c776;
	padding:10px;
	text-align:center;
	display:block;
}
.AdminChangeLog .SubTitle {
	font-size:16px;
	font-family:Trajan;
	color:#fff;
	padding:10px;
	display:inline-block;
}
.AdminChangeLog .Table td {
	text-align:center;
	margin:auto;
}
.AdminChangeLog .LeftAligned {
	float:left;
}
.AdminChangeLog .RightAligned {
	float:right;
}
.AdminChangeLog .LineBreak {
	height:1px;
	border:0px;
	padding:0px;
	margin:0px;
	background-color: #fff;
}
.AdminChangeLog .Special {
	font-size:30px;
	font-family:Trajan;
	font-weight:bold;
	color:#e9c776;
	padding:10px;
	display:inline-block;
	float:right;
	cursor:pointer;
}
.LogInPortal {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	border: 1px solid #e9c776;
	margin: auto;
	padding: 8px;
	font-family: Vinque;
	font-size: 16px;
}

.trainingLinks {
	text-decoration: underline;
	cursor:pointer;
	color:#8B0000;
}
.trainingLinks:hover {
	color:#CD5C5C;
}
.trainingLinks:active {
	color:#B22222;
}

.comma-list {
    display: inline;
    list-style: none;
    padding: 0px;
}

    .comma-list li {
        display: inline;
    }

        .comma-list li::after {
            content: ", ";
        }

        .comma-list li:last-child::after {
            content: "";
        }

.scenario-editor textarea
{
    width: 100%;
}

.scenario-editor input
{
    width: 49%;
}


.TicketBox {
	border: solid 2px #e9c776;
	padding: 20px;
	margin: auto;
	width: 80%;
	font-size: 18px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}
.TicketBox .Title {
	font-size: 24px;
	font-family: Trajan;
	color: #fff;
	display: inline-block;
}
.TicketBox .TicketTable {
	margin:auto;
	border: solid 2px #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	width: 90%;
}
.TicketBox .TicketTable th {
	font-size: 22px;
	font-family: MedievalSharp;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
	border: solid 1px #000;
	color: #e9c776;
	text-align: center;
}
.TicketBox .TicketTable .Msg {
	font-size: 14px;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	width: 150px;
  	display:inline-block;
}
.TicketBox .TicketTable .Button {
	cursor: pointer;
	padding: 4px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #fff;
	border: 3px solid #000;
	font-family: Trajan;
	width: 100%;
}
.TicketBox .TicketTable .Button:hover {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
	color: #e9c776;
	border: 3px solid #e9c776;
}
.TicketBox .TicketTable .Button:active {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	border: 3px solid #e9c776;
}

.ReplyBox {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    margin: auto;
    padding: 15px;
    padding-bottom: 100px;
    border: 3px solid #000;
    width: 75%;
    height:auto;
}
.ReplyBox .Close {
	float: right;
	display: inline-block;
	font-weight: bold;
	font-size: 30px;
	margin: 0px;
	cursor: pointer;
	border: 1px solid #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
}
.ReplyBox .Close:hover {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
	color: #fff;
	border: 1px solid #e9c776;
}
.ReplyBox .Close:active {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
}
.ReplyBox .Title {
	font-size: 24px;
	font-family: Trajan;
	color: #fff;
	display: inline-block;
}
.ReplyBox .Button {
	display: block;
	font-size: 24px;
	text-align: center;
	margin: auto;
	margin-top: 15px;
	padding: 10px;
	font-family: MedievalSharp;
	cursor: pointer;
	border: 1px solid #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
}
.ReplyBox .Button:hover {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
	color: #fff;
	border: 1px solid #e9c776;
}
.ReplyBox .Button:active {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
}
.ReplyBox .ReplyTable {
	border-top: 2px solid #e9c776;
	border-bottom: 2px solid #e9c776;
	font-size: 20px;
	text-align: left;
	margin: auto;
	width: 90%;
}
.ReplyBox .ReplyTable .Name {
	font-size: 22px;
	font-family: MedievalSharp;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
	border: solid 1px #000;
	color: #e9c776;
	padding: 10px;
	margin: auto;
}
.ReplyBox .ReplyTable .Comment {
	font-size: 16px;
	padding: 10px;
}
.ReplyBox .ReplyTable .OddRow {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}

.HospitalWindow .Close {
	float: right;
	display: inline-block;
	font-weight: bold;
	font-size: 30px;
	margin: 0px;
	cursor: pointer;
	border: 1px solid #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	padding: 5px;
}
.HospitalWindow .Refresh {
	float: left;
	display: inline-block;
	font-weight: bold;
	font-size: 30px;
	margin: 0px;
	cursor: pointer;
	border: 1px solid #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	padding: 5px;
}
.HospitalWindow .Title {
	font-size: 24px;
	text-align: center;
	font-family: Trajan;
}
.HospitalWindow .Table {
	margin: auto;
	width: 100%;
	font-size: 16px;
}
.HospitalWindow .Table .TableHeading {
	font-size: 22px;
}
.HospitalWindow .Table .Right {
	float: right;
}
.HospitalWindow .Button {
	font-size: 16px;
	text-align: center;
	padding: 5px;
	font-family: MedievalSharp;
	cursor: pointer;
	border: 1px solid #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}
.HospitalWindow .Button:hover {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
	border: 1px solid #e9c776;
}
.HospitalWindow .Button:active {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}

.ActivityLog .Activity {
	border: 1px solid #000;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	padding: 10px;
	margin: 2px;
}

.HeroBox {
    display: flex;
    flex-direction: column;
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
    border: 1px solid #e9c776;
    width: 95%;
    margin: 5px auto;
}

.HeroBox table{
    font-size: 12px;
}

.HeroBox .OuterBox {
	display: flex;
	flex-direction: column;
    font-family: Trajan;
}
    .HeroBox .NameBox {
        display: flex;
        justify-content: space-between;
		cursor: pointer;
        font-size: 20px;
        padding: 8px;
        background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
        border-bottom: 1px #000 solid;
        font-family: MedievalSharp;
        color: #e9c776;
    }
    .HeroBox .InfoBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        flex-wrap: wrap;
    }

/*@media (max-width: 1400px) {
    .HeroBox .InfoBox {
        flex-direction: column;
        align-items: stretch;
    }
}*/

.InnerBox {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
    border: 1px #000 solid;
    margin: 5px;
    min-width: 0;
}

.InnerBox table {
    border-spacing: 10px 0;
}

    .InnerBox .TableHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
        border-bottom: 1px #000 solid;
        font-size: 20px;
        padding: 10px;
        height: 50px;
    }

        .InnerBox .TableHeader .button {
            font-family: Trajan;
            margin: 0px 20px;
        }

    .InnerBox .HeroData {
        display: flex;
        flex: 1 0 1;
        justify-content: space-around;
        padding: 20px 5px;
        overflow-x: auto;
    }

/*@media (min-width: 1000px) {
    .HeroBox .HeroData {
        height: 100%;
    }
}*/

            .HeroBox .HeroData.Top {
                align-items: flex-start;
            }

            .HeroBox .HeroData.Column {
                flex-direction: column;
            }

            .HeroBox .HeroData.Top.Column {
                align-items: center;
                margin: auto;
            }

            .HeroBox .HeroData .value {
                padding: 0px 20px;
                text-align: center;
            }


    .HeroBox .Goods {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        width: 100%;
    }

    .HeroBox .Good {
        background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
        padding: 5px;
        border: #000 solid 2px;
        margin: 5px;
    }

    .HeroBox .Arrow {
        font-size: 40px;
        padding: 5px;
    }


    .NoHeroesBox {
        font-size: 20px;
        background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
        text-align: center;
        padding: 30px;
        border: 3px #000 solid;
    }

.carousel {
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

        .carousel .carousel_container {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }
            .carousel .carouselItem.selected {
                color: #e9c776;
            }

        .carousel .carouselMenu {
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
        }



    .NewsItem {
        background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
        border: 3px #000 solid;
        width: 85%;
        margin: auto;
        margin-top: 18px;
    }

        .NewsItem .Title {
            font-family: MedievalSharp;
            background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
            padding: 20px;
            font-size: 24px;
            margin: 0;
            border-bottom: 1px #000 solid;
            color: #fff;
            display: block;
        }

            .NewsItem .Title:hover {
                background-image: url("/assets/images/ui/TLK_Menu_Background_Tile.png");
                color: #e9c776;
            }

        .NewsItem .Content {
            padding: 10px;
            font-size: 16px;
            display: -webkit-box;
		    -webkit-line-clamp: 10;
		    -webkit-box-orient: vertical;
		    line-height: 25px;
		    overflow: hidden;
		    text-overflow: ellipsis;
        }

        .NewsItem .DateTime {
            padding: 5px;
            color: #e9c776;
            text-align: right;
            font-size: 18px;
        }
.AgeBreakBox td {
	vertical-align: top;
}
.AgeBreakBox .Text {
	display: block;
	font-size: 16px;
	padding: 0px 10px 10px 10px;
}

.TimelapseVideo {
	margin: auto;
	padding: 10px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	border: 1px #e9c776 solid;
	width: 640px;
}

.RegisterBox {
	display: flex;
	flex-direction: column;
	padding: 10px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	border: 1px #000 solid;
	width: 80%;
	margin: auto;
	align-items: center;
	justify-content: center;
}
.RegisterBox .FormItem {
	width: 50%;
	margin: auto;
	margin-top: 10px;
}
.RegisterBox .FormItem .InnerItem {
	width: 100%;
}

.TournamentTable .MedalBox {
	display:flex;
	flex-direction:row;
	justify-content:space-around;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	padding: 10px;
	border: 3px solid #000;
	margin: 5px;
}
.TournamentTable .MedalBox img {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	border: 1px solid #e9c776;
	padding: 6px;
}
.TournamentTable h3 {
	font-size: 20px;
}

.SettingsBox {
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
	margin: auto;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.SettingsTable {
	margin: auto;
	width: 100%;
	border-collapse: collapse;
    border-spacing: 0;
}
.SettingsTable th {
	font-family: MedievalSharp;
	color: #e9c776;
	font-size: 1.25rem;
	padding: 10px;
    text-align: left;
}
.SettingsTable td {
	text-align: left;
	line-height: 25px;
    padding: 4px 10px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
}
.SettingsTable td:nth-child(odd) {
	border-right: 2px solid #000;
}
.SettingsTable td:nth-child(even) {
	border-left: 2px solid #000;
}
.progressBar
{
height:30px;
}
.percentage
{
	text-align:center;
}
.textCenter{
position:relative;
}

.glow
{
text-shadow: 0 0 4px #4d2600;
top: 15%;
}


.outter{
	width:100%;
	height:25px;
	border:solid;
	overflow:hidden;
	position:relative;
	margin:0 auto;
}
.inner{
    text-align: center;
    display: table-cell;
	height:25px;
	border-right:solid 1px #000;
	position:absolute;
	background: #ffb76b; /* Old browsers */
	background: -moz-linear-gradient(top, #ffb76b 0%, #ffa73d 49%, #ff7c00 77%, #ff7f04 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ffb76b 0%,#ffa73d 49%,#ff7c00 77%,#ff7f04 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ffb76b 0%,#ffa73d 49%,#ff7c00 77%,#ff7f04 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb76b', endColorstr='#ff7f04',GradientType=0 ); /* IE6-9 */
}
.center{
text-align:center !important;
}
.centerDonate
{
text-align:center;
margin:0 auto;
}
.centerTable{
margin: 0 auto;
}


.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.clickable-row:active, .clickable-row.activated {
    // Not used? check style if ever used
  background-color: #0e1215;
}

.ceasefire-title {
  font-size: 2rem;
	color: #e9c776;
	font-family: MedievalSharp;
  padding: 10px 0px 2px;
}

.ceasefire-description {
  font-size: 0.8rem;
	color: #e9c776;
  padding: 5px 0px;
  line-height: 11px;
}

.default-hr {
    margin-bottom: 0rem;
    border-style: inset;
    border-width: 1px;
}
