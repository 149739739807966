
input {
  font-size: 0.75rem;
  padding: 5px 5px;
  color: #E6EBEE;
  background-color: #355065;
  /*border : 1px solid #355065;*/
  border: 1px solid #000000;
}

select {
  border: #e9c776 solid 1px;
  -moz-appearance: none;
  text-indent: 0.01px;
  -webkit-appearance: none;
  background-image: url("/assets/images/ui/GoldArrow_Down.png"), url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
  color: #e9c776;
  font-family: Trajan;
  text-align: center;
  background-repeat: no-repeat, repeat;
  background-position: center right, top left;
  cursor: pointer;
  padding: 7px 25px 7px 5px;
  font-size: 0.8rem;
  border-radius: 3px;
}

select:hover {
  background-image: url("/assets/images/ui/GoldArrow_Down.png"), url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
}

select:active {
  border: #fff solid 1px;
  color: #fff;
}

option {
  color: #e9c776;
  background-color: #19130e;
  font-family: MedievalSharp;
  font-size: 1rem;
}

input[type=checkbox] {
	-webkit-appearance: none;
	background-image: url("/assets/images/ui/Checkbox_Unchecked.png");
	cursor: pointer;
	display: inline-block;
  padding: 5px;
}
input[type=checkbox]:checked {
	background-image: url("/assets/images/ui/Checkbox_Checked.png");
}

input[type=checkbox].large-checkbox {
  padding: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
}

input[type='radio'] {
  font-size: initial;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-image: url("/assets/images/ui/Checkbox_Unchecked.png");
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #e9c776;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-image: url("/assets/images/ui/Checkbox_Checked.png");
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #e9c776;
}

.textareaV2 {
	border: #e9c776 solid 1px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	font-family: MedievalSharp;
}

.inputV2 {
	border: #e9c776 solid 1px;
	background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Dark.png");
	color: #e9c776;
	font-family: MedievalSharp;
}

.inputV2.light, .textareaV2.light {
  background-image: url("/assets/images/ui/TLK_Menu_Background_Tile_Light.png");
  border: #000 solid 1px;
}

.inputV2.ng-invalid:not(.ng-untouched), .textareaV2.ng-invalid:not(.ng-untouched) {
	border: #F44336 solid 1px;
	color: #f4a460;
}
/*
.form-check-input
{
  color:gold;
}

.form-check-input:checked {
  background-color: #e9c776;
  border-color: silver;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#e9c776'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#silver'/%3e%3c/svg%3e");
}
*/
.form-switch .form-check-input {
  height: 15px;
  width: 30px;
}

.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(109, 82, 33, 1.0)'/></svg>");
}

.form-switch .form-check-input:checked {
  background-color: #e9c776;
  border-color: #e9c776;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(109, 82, 33,1.0)'/></svg>");
}

.input-adornment {
  position: absolute;
  top: 5px;
  right: 20px;
  color: #e9c776;
  cursor: pointer;
}

.input-adornment:hover {
  color: #fff;
}

.input-adornment:active {
  color: #ccc;
}

