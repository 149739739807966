@font-face {
  font-family: MedievalSharp;
  src: url(/assets/fonts/MedievalSharp.ttf);
  /* Development */
}

@font-face {
  font-family: Vinque;
  src: url(/assets/fonts/Vinque.ttf);
  /* Development */
}

@font-face {
  font-family: Trajan;
  src: url(/assets/fonts/Trajan_Pro_Bold.ttf);
}

@font-face {
  font-family: Rothenburg;
  src: url(/assets/fonts/rothenbg.ttf);
}

.Trajan {
  font-family: Trajan;
}

.MedievalSharp {
  font-family: MedievalSharp;
}

.Vinque {
  font-family: Vinque;
}
