@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

/* animation */

@keyframes slide {
  0% {
    transform: skewX(30deg) translateX(-200%);
  }

  100% {
    transform: skewX(30deg) translateX(100%);
  }
}

@keyframes timer {
  to {
    --percentage: 100%;
  }
}

.linear-spin {
    animation: spin 5s infinite linear;
}

.shine {
  content: '';
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.shine:after {
  content: '';
  top: 0;
  transform: skewX(30deg) translateX(-200%);
  width: 100%;
  height: 100%;
  position: absolute;
  /*z-index: 1;*/
  animation: slide 2s infinite;
  background: linear-gradient(to right, rgba(255,215,0,0) 0%, rgba(255,215,0,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* W3C */
}
