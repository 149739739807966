.Center {
  /*Replace with bootstrap text-center*/
      text-align: center;
}

.Right {
  /*Replace with bootstrap text-end*/
      text-align: right;
}

.nowrap {
  white-space: nowrap;
}

.Golden, .accent {
  color: #e9c776;
  fill: #e9c776;
}

.danger {
  color: #F44336;
  fill: #F44336;
}

.success {
  color: #64DD17;
  fill: #64DD17;
}

.warning {
  color: #ffc107;
  fill: #ffc107;
}

.bg-danger {
  background-color: #F44336;
}

.bg-success {
  background-color: #64DD17;
}

.bg-accent {
  background-color: #e9c776;
}

.bg-warning {
  background-color: #ffc107;
}

.darken-on-hover {
  cursor: pointer;
  transition: 0.2s;
}

.darken-on-hover:hover {
  color: #bbb;
}

.text-body-secondary
{
    color: #BBB !important;
}
